.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: OpenSansBold;
  src: url("./Assets/fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: OpenSansLight;
  src: url("./Assets/fonts/OpenSans-Light.ttf");
}

@font-face {
  font-family: OpenSansMedium;
  src: url("./Assets/fonts/OpenSans-Medium.ttf");
}

@font-face {
  font-family: OpenSansRegular;
  src: url("./Assets/fonts/OpenSans-Regular.ttf");
}

*:focus {
  outline: none;
}

.contentWidth {
  width: calc(100% - 257px);
}

.wo_detail_custom_height {
  height: calc(100% - 30px);
}

/* table {
  table-layout: fixed;
  width: 100%;
} */

table td {
  word-break: break-all;
  word-wrap: break-word;
}
